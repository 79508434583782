body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dashbord-card {
  position: relative;
  height: 100px;
  box-shadow:0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 187, 212, 0.4);
  background-color: #fff;
  border-radius: 12px;
  padding: 0.75rem;
  text-align: right;
  margin-top: 1.5rem;
}

.dashbord-card span {
  /* color: #7b809a; */
  color: #344767;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.375;
}



.dashbord-card hr {
  background-color: transparent;
  background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(51, 71, 103, 0.4), rgba(51, 71, 103, 0)) !important;
  height: 0.0625rem;
  padding: 0 !important;
  opacity: 0.25;
  border-bottom: none;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 0;
  flex-shrink: 0;
  margin: 0 !important;
  margin-top: 1.3rem !important;
  margin-bottom: 0.5rem !important;
}

.dashbord-card .next {
  color: #7b809a;
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 400;
}

.dashbord-card .icon {
  position: absolute;
  top: -16px;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  background: var(--color-gradiant-black);
  text-align: center;
  border-radius: 10px;
  color: #fff;
}

.dashbord-card .icon svg {
  font-size: 2rem;
}

@media (max-width: 768px) {
  .dashbord-card span {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375;
  }
  .dashbord-card .next {
   display: none;
  }
  .dashbord-card hr{
    display: none;
  }
  .dashbord-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }
  .dashbord-card .icon {
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    background: var(--color-gradiant-black);
    text-align: center;
    border-radius: 10px;
    color: #fff;
  }

  .dashbord-card .icon svg {
    font-size: 1.5rem;
  }

  .drawer{
    margin: 0 !important;
    border-radius: 0 !important;
  }
}