.form-box {
    background: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 10px;
    min-height: 1px;
    -ms-overflow-style: none;
    overflow-x: auto;
    transition: .2s ease all;
    box-shadow: rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem;
    margin-bottom: 1.5rem;
}

.LoginSection {
    background-color: #080710 !important;
}


.LoginSection .background {
    width: 430px;
    height: 520px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.LoginSection .background .shape {
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
}

.LoginSection .shape:first-child {
    background: linear-gradient(#1845ad,
            #23a2f6);
    left: -80px;
    top: -80px;
}

.LoginSection .shape:last-child {
    background: linear-gradient(to right,
            #ff512f,
            #f09819);
    right: -30px;
    bottom: -80px;
}

.LoginSection form {
    height: 520px;
    width: 100%;
    max-width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
}

.LoginSection form * {
    /* font-family: 'Poppins', sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.LoginSection form h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
}

.LoginSection label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}

.LoginSection input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}

::placeholder {
    color: #e5e5e5;
}

.LoginSection button {
    margin-top: 55px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 8px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.LoginSection button:hover {
    background-color: var(--color-logo-name);
    color: var(--color-logo-no);
}

.LoginSection .social {
    margin-top: 30px;
    display: flex;
}

.LoginSection .social div {
    background: red;
    width: 150px;
    border-radius: 3px;
    padding: 5px 10px 10px 5px;
    background-color: rgba(255, 255, 255, 0.27);
    color: #eaf0fb;
    text-align: center;
}

.LoginSection .social div:hover {
    background-color: rgba(255, 255, 255, 0.47);
}

.LoginSection .social .fb {
    margin-left: 25px;
}

.LoginSection .social i {
    margin-right: 4px;
}

.LoginSection .error {
    color: red;
    border: 2px solid red;
}

@media (max-width:425px) {
    .LoginSection form {
        height: 450px;
        width: 90%;
    }

    .LoginSection input {
        height: 40px;
        border-radius: 20px;
    }

    .LoginSection button {
        background-color: var(--color-logo-name);
        color: var(--color-logo-no);
        border-radius: 20px;
    height: 38px;
    }
}





.ChangepasswordSection .background {
    width: 430px;
    height: 520px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.ChangepasswordSection .background .shape {
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
}

.ChangepasswordSection .shape:first-child {
    background: linear-gradient(#1845ad,
            #23a2f6);
    left: -80px;
    top: -80px;
}

.ChangepasswordSection .shape:last-child {
    background: linear-gradient(to right,
            #ff512f,
            #f09819);
    right: -30px;
    bottom: -80px;
}

.ChangepasswordSection form {
    height: 520px;
    width: 100%;
    max-width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
}

.ChangepasswordSection form * {
    /* font-family: 'Poppins', sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.5px;
    outline: none;
  
}

.ChangepasswordSection form h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
}

.ChangepasswordSection label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}

.ChangepasswordSection input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}

::placeholder {
    color: #e5e5e5;
}

.ChangepasswordSection button {
    margin-top: 55px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 8px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.ChangepasswordSection button:hover {
    background-color: var(--color-logo-name);
    color: var(--color-logo-no);
}

.ChangepasswordSection .social {
    margin-top: 30px;
    display: flex;
}

.ChangepasswordSection .social div {
    background: red;
    width: 150px;
    border-radius: 3px;
    padding: 5px 10px 10px 5px;
    background-color: rgba(255, 255, 255, 0.27);
    color: #eaf0fb;
    text-align: center;
}

.ChangepasswordSection .social div:hover {
    background-color: rgba(255, 255, 255, 0.47);
}

.ChangepasswordSection .social .fb {
    margin-left: 25px;
}

.ChangepasswordSection .social i {
    margin-right: 4px;
}

.ChangepasswordSection .error {
    color: red;
    border: 2px solid red;
}

@media (max-width:425px) {
    .ChangepasswordSection form {
        height: 450px;
        width: 90%;
    }

    .ChangepasswordSection input {
        height: 40px;
        border-radius: 20px;
    }

    .ChangepasswordSection button {
        background-color: var(--color-logo-name);
        color: var(--color-logo-no);
        border-radius: 20px;
    }
}