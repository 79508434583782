:root {
  --color-logo-name: #009270;
  --color-logo-no: #ffd700;
  /* --color-background: #1f2120c5; */
  /* --color-background: #000000; */
  --color-background: rgb(53, 50, 50);

  --color-gradiant-black: linear-gradient(195deg, #42424a, #191919);
  --color-gradiant-blue: linear-gradient(195deg, #49a3f1, #1A73E8);
  --color-gradiant-green: linear-gradient(195deg, #66BB6A, #43A047);
  --color-gradiant-pink: linear-gradient(195deg, #EC407A, #D81B60);
  --color-gradiant-orange: #fb8c00;


}

.fullScreenButton{
  opacity: 0.5;
}
.fullScreenButton:hover{
  opacity: 1;
}

button {}

table {
  border-collapse: collapse !important;
}

th {
  white-space: nowrap !important;
  text-transform: uppercase;
  background: var(--color-gradiant-black);
  color: #fff !important;
  border-color: black;
}

td {
  white-space: nowrap !important;
  border-color: black;
  border-collapse: collapse !important;
}

#style-1::-webkit-scrollbar-track {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#style-1::-webkit-scrollbar {
  width: 5px;
  background-color: #F7F7F7;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d7d7d7;
}

.MuiStack-root {
  width: 100%;
  overflow: unset !important;
}

.go-back {
  font-size: 14px;
  margin-bottom: 0.5rem;
  background-color: #5856D6 !important;
}

.go-back svg {
  font-size: 18px;
  margin-bottom: 0.2rem;
}

p {
  padding: 0;
  margin: 0;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sidebar-logo {
  color: var(--color-logo-name) !important;
  white-space: nowrap;
}

.layout-if-side-bar-open {
  width: calc(100% - 300px);
}

.layout-if-side-bar-close {
  width: calc(100% - 62px);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.headerSearchItem {
  position: relative;
  /* z-index: 999999; */
  border: 1px solid #009270;
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
  width: 100%;
}

.headerSearchText {
  padding: 0 0.5rem;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.head-title {
  color: white;
  background-color: var(--color-background);
  padding: 0.5rem;
  border-radius: 8px;
  font-weight: bold;
}

.mobileview {
  display: none !important;
}

.only-for-mobile {
  display: none !important;
}

.Desktopview {
  display: block;
}

@media (max-width:767px) {
  .only-for-mobile {
    display: block !important;
  }

  .Desktopview {
    display: none !important;
  }


  .mobileview {
    display: block !important;
  }

  .mobileview {
    display: flex !important;
  }



  .layout-if-side-bar-open {
    width: 100%;
  }

  .layout-if-side-bar-close {
    width: 100%;
  }


  .calendar-position {
    display: 'flex';
    padding-top: '2%';
    padding-left: '2%'
  }

  .example-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  #myGrid {
    flex: 1 1 0px;
    width: 100%;
  }

  .example-header {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .ag-row-level-0 {
    background-color: #cc222244 !important;
  }

  .ag-row-level-1 {
    background-color: #33cc3344 !important;
  }

  .ag-row-level-2 {
    background-color: #2244cc44 !important;
  }

  .legend-item {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
  }

  .legend-label {
    position: relative;
    top: -5px;
    margin-right: 20px;
  }
}

@media (max-width:425px) {
  .headerSearchItem {
    width: 100%;
  }

  button {
    font-size: 12px !important;
    padding: 0.3rem !important;
  }
}