.customer-table-responsive {
  display: block;
}

.tableTool-button {
  display: flex;
  width: 100%;
}

.statement Button {
  margin: 10px 4px;
  width: inherit;
}


.mobile-card-table-main {
  margin-top: 0.5rem;
  display: none;
}

.mobile-card-table {
  /* border-left: 6px solid red; */
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  width: 100%;
  overflow: hidden;
  margin-top: 0.5rem;
}

.mobile-card-table table {
  width: 100%;
}

.mobile-card-table table tr th {
  background-color: #009272;
  color: white !important;
  padding: 0 0.7rem;
}

.mobile-card-table table tr th svg {
  color: white !important;
}

.mobile-card-table table tbody tr:nth-last-of-type(odd) {
  background-color: #dcdcdc1e;
}

.mobile-card-table table tbody tr td {
  padding: 0.2rem 0.7rem;
}

@media (max-width:768px) {

  /* .customer-table-responsive {
    display: none;
  } */
   

  .mobile-card-table-main {
    display: block;
  }
}

.activematches{
  width: 100%;
  overflow-y: auto;
}



.activematches table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.activematches table thead th {
  background-color: #3d4553;
  color: white;
  text-align: left;
  padding: 12px;
  border: 2px solid white;
}

.activematches tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}



.activematches tbody tr:hover {
  background-color: #f1f1f1;
}

.activematches td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  border: 2px solid white;
}

.activematches button {
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.activematches button:hover {
  opacity: 0.9;
}

.activematches .inplay-btn {
  background-color: #807ee9;
  color: white;
  border: none;
}

.activematches .details-btn {
  background-color: transparent;
  color: #007bff;
  border: none;
  text-decoration: underline;
}


.ledgertab{
  width: 100%;
  overflow-y: auto;
}



.ledgertab table {
  width: 100%;
  border-collapse: collapse;
  margin: 0px 0px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.ledgertab table thead th {
  background-color: #3d4553;
  color: white;
  border: 2px solid white;
  padding: 10px;
  text-align: center;
}

.ledgertab tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}



.ledgertab tbody tr:hover {
  background-color: #f1f1f1;
}

.ledgertab td {
  border-bottom: 1px solid #ddd;
  border: 2px solid white;
  padding: 5px;
}

.ledgertab button {
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.ledgertab button:hover {
  opacity: 0.9;
}

.ledgertab .inplay-btn {
  background-color: #807ee9;
  color: white;
  border: none;
}

.ledgertab .details-btn {
  background-color: transparent;
  color: #007bff;
  border: none;
  text-decoration: underline;
}



/* aatrangi */
/* TableSystum.css */
.header-Report-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header-row {
  padding: 10px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.superAd {
  background-color: #9400D3;
  /* background-color: #000; */
}

.admin {
  /* background-color: #3886c6; */
  background-color: #4b0082;
}

.subadmin {
  /* background-color: #5f8d2d; */
  background-color: #0000ff;
  padding-left: 80px;
}

.master {
  background-color: #00ff00;
  /* background-color: #8e63a2; */
  padding-left: 160px;
}

.superagent {
  background-color: #a0a050;
  /* background-color: #7864a5; */
  padding-left: 240px;
}

.agent {
  /* background-color: #5f8d2d; */
  background-color: #ff7f00;
  padding-left: 320px;
}

.table-container-Report {
  overflow-x: auto;
  margin-top: 20px;
}

.table-container-Report table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.table-container-Report table th,
td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.table-container-Report table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.table-container-Report table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-container-Report table tr.total-row {
  font-weight: bold;
  background-color: #ffeaea;
}


.fancy-bet table tr td {
  border: 2px solid white;
  font-size: 12px;
  padding: 8px !important;
}

.fancy-bet table {
  background-color: #f2f2f2;
  width: 100%;
}


.fancy-bet-table {
  overflow-x: auto;
}

.fancy-bet tr th {
  padding: 0px 10px;
  background-color: #cda9c7;
  text-align: center !important;
  font-size: 14px;
}

.fancy-bet table tr {
  text-align: center;
  border-width: 1;
  border: 1px solid;
}

#tree-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #f0f0f0;
  overflow: auto;
  width: 100%;
}

#tree-table-container {
  position: absolute;
  left: 0%;
  transform: translate(-50%, -50%);

}