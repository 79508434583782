.drawer {
    margin: 1rem;
    border-radius: 12px;
    height: calc(100vh - 2rem);
    background: linear-gradient(195deg, #42424a, #191919);
    transition: all 0.3s ease;
    /* overflow: hidden; */
}


.outletdesign {
    height: 90vh;
    overflow-y: auto;
    padding: 1.5rem;
    background-color:#bcbcbc;

  
}

.openDrawer {
    width: 300px;
}

.closeDrawer {
    width: 57px;
}

.drawer-content {
    padding: 10px 0;
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    overflow-y: auto;
    height: inherit;
}


.drawer-content ul {
    list-style: none;
    padding: 0px;

}

.drawer-content ul li {
    padding: 10px;
}

.drewar-items a {
    text-decoration: none;
}

.drewar-items li {
    color: #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
}

/* .drewar-items .active li{
    background: #cdcdcf13;
} */
.table-head-bar {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px;
    justify-content: left;
    color: #212529;
}

.cardtable-head-bar {
    border: 1px solid #000;
    border-radius: 18px;
    padding: 0 10px;
    margin-right: 0.5rem;
    overflow: hidden;
}

.cardtable-head-bar input {
    padding-left: 0.75rem;
    margin: 0.2rem 0;
}

.table-head-bar i {
    font-size: 20px;
    /* margin-right: 0.5rem; */
    margin-top: 0.1rem;
    cursor: pointer;
    font-weight: bold;
}

.table-head-bar input {
    outline: none;
    width: 100%;
    border: none;
    font-size: 18px;
}

.table-head-bar h1 {
    border-right: 1px solid rgba(0, 0, 0, .12);
    margin-right: 1rem !important;
    padding-right: 1rem;
    font-size: 20px;
    margin-bottom: 0;
    white-space: nowrap;
}

.drewar-items li div {
    display: flex;
    align-items: center;
}

.drewar-items li div svg {
    margin-left: 3px;
    margin-right: 1.2rem;
    /* color: var(--color-logo-no); */
    /* color: rgb(126 131 139); */
    padding: 5px;
    font-size: 35px;
    background: linear-gradient(195deg, #42424a, #191919);
    border-radius: 50%;
}

.drewar-items .active li {
    /* background: #cdcdcf13 !important; */
}

.drewar-items li:hover {
    background: #cdcdcf13;
}

.drewar-items li span {
    rotate: 180deg;
}

.drewar-items .subItem svg {
    font-size: 12px;
    margin-left: 0.3rem;
    margin-right: 0.9rem;
}

/* 
Master Admin */

.master-admin {
    border-radius: 20px;
}

.master-admin table thead tr {
    border-collapse: collapse;

}

.master-admin table thead tr th {
    padding: 0.5rem;
    border-color: #adabab;
    white-space: nowrap;
}

.master-admin table tbody tr td {
    padding: 0.5rem;
}



.SignUpSection {
    border: 1px solid #dcdcdc;
    padding: 4rem 2rem;
    background: rgb(88 86 214);
    width: 40%;
    text-align: center;
    color: #fffF;
}

.registerSection {
    border: 1px solid #dcdcdc;
    padding: 3rem 2rem;
    width: 50%;
}

.registerSection p {
    color: rgb(37 43 54 / 68%);
}


/* table */
/* .css-du4ylq-MuiTableContainer-root {
    background-color: aliceblue;
} */

.cmd {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cmc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cmb {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

h4 {
    color: #5856D6
}

.table {
    width: 100%;
    border-collapse: collapse;
    padding: 20px;
}

.table th,
.table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #82a9f8;
}


/* .offDrawer{
    width: 0px;
} */


.clientlenadena div span {
    font-size: 25px;
    color: white;
}


.hideDrawer {
    display: none;
    width: 100%;
}

.mobileDrawer-body .hideDrawer {
    display: block !important;
    width: 100%;
    height: auto;
}

.mobileDrawer-body .MuiDrawer-paper {
    background: #1b1b1c;
}

.mobileDrawer {
    display: none;
}



.dialog-container {
    border-radius: 20px 20px 0px 0px;
    padding: px;
    background-color: #ffffff;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dialog-container h5 {
    color: var(--color-background);
    font-size: 500;
    padding-top: 10px;
}


.dialog-container table td {
    border-radius: 20px;
}

.dialog-container table th {
    background-color: #dee9ff;
}

.swipe1 {
    position: relative;
    /* Ensure proper positioning for animation */
    animation: slideIn 0.2s ease;
}

.swipe2 {
    position: relative;
    /* Ensure proper positioning for animation */
    animation: slideOut 0.5s ease;
}

.swipe3 {
    position: relative;
    /* Ensure proper positioning for animation */
    animation: slideIn 0.2s ease;
}


.swipe4 {
    position: relative;
    /* Ensure proper positioning for animation */
    animation: slideOut 0.5s ease;
}


.swipe5 {
    position: relative;
    /* Ensure proper positioning for animation */
    animation: slideIn 0.2s ease;
}


.swipe6 {
    position: relative;
    /* Ensure proper positioning for animation */
    animation: slideOut 0.5s ease;
}




@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOut {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}



.lanedane-container .items {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding: 0.5rem 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;
    height: 100px;
}

.lanedane-container .items span {
    font-size: 20px;
    font-weight: bold;
    color: white;

}

.lanedane-container .cmd {
    padding: 10px 0px;
}

.ledger-lena{
background-color: blue;
}

 .ledger-dena{
    background-color: red;
    }

.closeDrawer {
    position: relative;

}

.closeDrawer ul .subItem {
    position: absolute;
    z-index: 1000;
    background-color: var(--color-background);
    width: 200px;
}


.closeDrawer .drewar-items li .titleitem a {
    display: none;
}

.closeDrawer .drewar-items li span {
    display: none;
}

.kkk {
    color: #ba3e3e;
}

.MuiTableHead-root tbody tr td {
    border: 1px solid rgb(181, 78, 78);
}

/* ///////dashbord////// */


.dashbord {
    width: 100%;
}

.dashboard-details .dashbord-item:hover {
    background-color: var(--color-background) !important;

}

.dashbord .dashbord-item {
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
 
    color: black;
    padding: 15px 10px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.dashbord .dashbord-item{
    background-color: #ffffffd8;
    color: #191919;
}

.dashbord .dashbord-item .image-container img {
    height: 60px;
    width: auto;
}

.dashbord .dashbord-item .image-container {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    display: flex;
    align-items: center;
    justify-content: center;
color: #ffffff;
background-color: #575658!important;
}

.dashbord .dashbord-item span {
    font-size: 16px;
    padding-left: 17px;
    font-weight: bold;
    color: #19191a;
}

.dashbord .dashbord-item span:hover span {
    color: #dcdcdc;
}

.sec-itm {
    background-color: #e16969 !important;
}


.sec-itm:hover {
    background-color: #fc9f9f !important;
}

.sec-it {
    background-color: #b0b0b0 !important;
}


.gamebackground {
    background-image: url("../assets/images/greentable.jpg");
    background-size: 100% 100%;
    opacity: 3;
}




.login-body {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.login-image {
    height: 100%;
    width: 100%;
    margin: auto;
}

.login-image img {
    width: 100%;
}

.tech_error_field {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;


}

.technical_error {
    text-align: center;
    font-family: cursive;
    justify-content: center;
}

.technical_error h1 {

    font-size: 10rem;
    font-weight: bold;
    color: var(--color-main);
    margin-bottom: 0;
    line-height: 10rem;
}

.technical_error h3 {
    font-size: 3rem;
    font-weight: 700;
}

.technical_error p {
    color: #BCC0C3;
}


.backbutton:hover {
    background-color: #5698d6;
    color: white !important;
}


.league-list {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    height: 80vh;
    overflow-y: auto;

}

.league-head {
    background-color: #807EE9;
    padding: 10px 10px;
    font-size: 22px;
    color: #ffffff;
    font-weight: 500;
}

.league-data {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.league-data span {
    margin-right: 10px;
}

.league-table {
    width: 100%;
    text-align: left;
}


.league-table tr th {
    background-color: #e0e0e0;
    padding: 10px 10px;
}

.league-table tr td {
    padding: 10px 10px;

}



@media(max-width:767px) {
    .mobileDrawer {
        display: block;
    }

    .mobileDrawer-body {
        display: block;
    }

    .lanedane-container .items {
        height: auto;
        border-radius: 8px;
    }

    .lanedane-container .items span {
        font-size: 14px;
    }

    .dashbord .dashbord-item .image-container img {
        height: 35px;
        width: auto;
    }

    .dashbord .dashbord-item .image-container {
        background-color: #ffffff;
        width: 50px;
        height: 50px;
        font-size: 14px !important;
    }

    .dashbord .dashbord-item span {
        font-size: 14px;
        font-weight: bold;
    }

    .dashbord .dashbord-item {
        padding: 10px;
    }
}


@media (max-width:601px) {}

@media (max-width:425px) {
    .dashbord .dashbord-item .image-container img {
        height: 30px;
        width: auto;

    }

    .dashbord .dashbord-item .image-container {
        /* background-color: transparent; */
        background-color: #e0e0e0;
        width: 40px;
        height: 40px;
        font-size: 13px !important;
    }

    .dashbord .dashbord-item span {
        font-size: 12px;
        font-weight: bold;
    }

    .dashbord .dashbord-item {
        padding: 10px;
    }

    .outletdesign {
        padding: 1.5rem 0.5rem;
    }
}




/* rules style  */



.section-rules h2 {
    color: #333;
    font-size: 1.5em;
    margin-top: 20px;
}

.section-rules ul {
    margin-bottom: 20px;
}

.section-rules ul li {
    margin-bottom: 10px;
}

.section-rules .note {
    font-style: italic;
    color: #555;
}





.teenpatti .box-6 {
    width: 60%;
    background-color: #b5c7ce;
    padding: 0px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.teenpatti .box-4 {
    width: 40%;
    background-color: #b0b4ef;
    padding: 0px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.teenpatti table {
    width: 100%;
    background-color: #bfd7fa;
    margin-top: 1rem;
    border-radius: 6px;
}

.teenpatti tbody td {
    padding: 8px;
    font-weight: 500;
    border: 2px solid rgb(255, 255, 255);
    font-size: 18px;

}

.teenpatti-betsection {
    overflow: hidden;
    border-radius: 6px;
}

.teenpatti-betsection table {
    border-radius: 6px;
}

.teenpatti-betsection .betting-rule {

    border-radius: 10px;
    padding: 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.teenpatti-betsection .place-bet {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}


.overflow-teenpatti {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.overflow-dragon-tiger-btn1 {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition-property: all;
    top: 50%;
    /* Adjust to center vertically */
    left: 50%;
    /* Adjust to center horizontally */
    background-color: rgba(0, 0, 0, 0.3);
    transform: translate(-50%, -50%);
    /* Center the lock icon perfectly */
    z-index: 10;
    /* Ensure it appears above the button */
    display: flex;
    justify-content: center;
    align-items: center;
}

.overflow-dragon-tiger-card {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-property: all;
    height: 100%;
    text-align: center;
    top: 50%;
    /* Adjust to center vertically */
    left: 50%;
    /* Adjust to center horizontally */
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate(-50%, -50%);
    /* Center the lock icon perfectly */
    z-index: 10;
    /* Ensure it appears above the button */

}



.casinoifram iframe {
    min-height: 206px;
    /* Default for mobile screens */
}

/* For larger screens (you can adjust the pixel value as needed) */
@media (min-width: 768px) {

    /* Example: Tablets and larger screens */
    .casinoifram iframe {
        min-height: 351px;
    }

    .live_stream {
        width: 100%;
        height: 360px;
        display: flex;
        background-color: #000;
        justify-content: center;
        align-items: center;
    }

}

.teenpatti-header {
    background-color: #343a40;
    color: #fff;
    margin: 10px 0px;
    font-size: 12px;
    border-radius: 6px;
    padding: 0.5rem 1rem;
}

.andarbaharscroll {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    max-width: 100%;
    /* Enable horizontal scrolling */
    white-space: nowrap;
    /* Ensure items don't wrap to the next line */
    max-width: 250px;
    /* Set a fixed width for the container */
    scrollbar-width: none;
    /* For Firefox, hide scrollbar */
}

.andarbaharscroll::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}

.andarbaharscroll div {
    flex: 0 0 auto
}

.css-ypiqx9-MuiDialogContent-root {
    padding: 0px;
}


/* 
  background-color: #000000;
  width: 100%;
  height: 311px;
  display: flex;
  justify-content: center;
  align-items: center; */


.main-websocket {
    width: 100%;
}



.min-max {
    display: table-footer-group;
    font-size: 14px;
    font-weight: 500;
    color: #5a5a5a;
}

/* // dragon css  */
.teenpatti-betlist-container {
    border-radius: 10px;
    padding: 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.card-image {
    height: 42px;
    display: block;
}

.scorecard .sr-lmt-plus__segment {
    display: none;
}


.scorecard .sr-simcrick-mom {
    display: none;
}

.scorecard .sr-tabs__flexcontainer {
    display: none;
}

.scorecard .sr-simcrick__comp-wrap {
    width: 100%;
}

.scorecard .srm-notLmt {
    display: none;
}

.scorecard .sr-simcrick-pitch__bg {
    height: 150px;
}

.scorecard .sr-simcrick__cmp-inner {
    height: 200px;
}




.live_stream p {
    color: #ffe307;
    font-size: 11px;
    margin: 0px;
    font-weight: bold;
}

.live_stream {
    position: relative;
}

.overflow-card-section {
    position: absolute;
    top: 5px;
    left: 6px;
    font-size: 12px;
}

.overflow-card-section img {
    width: 35px;
    height: 45px;
    padding: 2px;
}


@media (max-width: 768px) {
    .overflow-card-section img {
        width: 22px;
        height: 27px;
        padding: 2px;
    }

}

.last_result {
    background-color: #343a40;
    color: white;
    font-size: 14px;
    margin-bottom: 0.1rem;
    border-radius: 6px;
    padding: 0.5rem 1rem;
}


.bet-result {
    text-align: end;
    overflow-x: auto;
}


.bet-result span {
    background-color: #209154;
    color: #ffffff;
    padding: 5px 9px;
    font-size: 10px;
    margin: 0px 2px;
    border-radius: 50px;

}


.tv-section {
    align-items: center;
    height: 50px;
    background-color: #9b6191;
}

/* UserDetail */


.userdetail-dashboard .mobileDrawer {
    display: block;
}

.userdetail-dashboard .mobileDrawer-body {
    display: block;
}

.userdetail-dashboard .lanedane-container .items {
    height: auto;
    border-radius: 8px;
}

.userdetail-dashboard .lanedane-container .items span {
    font-size: 14px;
}

.userdetail-dashboard .dashbord .dashbord-item .image-container img {
    height: 35px;
    width: auto;
}

.userdetail-dashboard .dashbord .dashbord-item .image-container {
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    font-size: 14px !important;
}

.userdetail-dashboard .dashbord .dashbord-item span {
    font-size: 14px;
    font-weight: bold;
}

.userdetail-dashboard .dashbord .dashbord-item {
    padding: 10px;
}